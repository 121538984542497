<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="pb-4" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="start" class="">
                <v-col cols="auto" class="m-0 p-0">
                  <v-avatar dark :color="hexTints(area.cor,0.8)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="m-0 p-0 ms-2">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    Metas de compreensão
                  </div>
                </v-col>
                <v-col>
                  <spanblocked :area="area" equipeID="gestores" class="right">
                    <v-btn color="cor.active.S" class="ms-1 px-1 f-sanspro text-nonecase" x-small @click="adicionarMeta">
                      <i v-i:duo#plus-circle#16 class="btn-icon-left"></i>
                      Adicionar <span class="ms-1 fw-700">meta</span>
                      <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
                    </v-btn>
                  </spanblocked>
                  <spanblocked :area="area" equipeID="gestores" class="right">
                    <v-btn v-if="metasDescontinuadas.length > 0" class="ms-2 me-2 px-1 text-nonecase f-sanspro fw-300 fs-10pt" v-cHex="hexShades(area.cor,0.2)" outlined x-small @click="$emit('listaDescontinuados','metas')">
                      <span class="f-sanspro fw-800 fs-9pt">{{metasDescontinuadas.length}}</span>
                      <span class="f-sanspro fw-600 ms-1 fs-8pt" v-if="metasDescontinuadas.length == 1">descontinuada</span>
                      <span class="f-sanspro fw-600 ms-1 fs-8pt" v-if="metasDescontinuadas.length>1">descontinuadas</span>
                      <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
                    </v-btn>
                  </spanblocked>
                  <spanblocked v-if="false" :area="area" equipeID="gestores" class="right">
                    <v-btn v-if="questoes.length > 0" class="text-nonecase f-sanspro fw-300 fs-10pt" :color="area.cor" text x-small>
                      <span class="ms-0 me-1 mx-0 rounded py-0 ps-4 pe-1 fs-8pt fw-600 f-sanspro" v-c:B :style="'background-color:'+area.cor">
                        <span v-i:duo#star#12 class="absolute" style="left: 2px; top: -2px;" v-c:B></span>
                        A2
                      </span>
                      <span class="f-sanspro fw-800 fs-9pt" v-cHex="hexShades(area.cor,0.2)">{{questoes.length}}</span>
                      <span class="f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexShades(area.cor,0.2)" v-if="questoes.length == 1">questão</span>
                      <span class="f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexShades(area.cor,0.2)" v-if="questoes.length>1">questões</span>
                      <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
                    </v-btn>
                  </spanblocked>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-container fluid class="">
            <v-row dense>
              <v-col cols="12" class="mt-0 p-1 text-justify" v-if="false">
                <v-alert type="info" v-c:P :color="hexTints(area.cor,0.85)" class="m-0 p-2 mt-2 fs-10pt">
                  Aguardando 1a. atualização
                </v-alert>
              </v-col>
              <v-col cols="12" class="mt-1 p-1 text-justify" v-if="metas.length > 0">
                <div class="col-12 col-lg-12 mt-0 pt-1" v-for="(meta,idx,index) in metas" :key="idx">
                  <metaitem :areaSel="area" :ucSel="uc" :metaSel="meta" :key="index+refresh"/>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <textareadialog ref="txtareadialog" :uc="ucSel" :area="area" fieldTitle="Meta de compreensão" mensagem="teste" @changeValue="novoTexto" />
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import metaitem from '@/views/cursos/planosv2/metas/metaItem.vue'
import textareadialog from '@/views/cursos/planosv2/metas/textAreaDialog.vue'
import moment from 'moment';
import spanblocked from "./spanBlocked.vue"
import feedcontroler from '@/views/feedsComponent/feedControler.vue'

export default {
  name: "metasView",
  components: { metaitem, textareadialog, spanblocked },
  props: {
    noedit: { default: false, type: Boolean },
    parse: { default: false, type: Boolean },
    area: { default: null, type: Object },
    ucSel: { default: null, type: Object },
  },
  computed: {
  },
  data(){
    return {
      uc: null,
      metas: [],
      metasDescontinuadas: [],
      questoes: [],
      loading: false,
      refresh: 0,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      console.log("metasView change uc");
      this.buildMetas();
    }
  },
  mounted: function() {
    var self = this;
    this.buildMetas();
  },
  methods:{

    pushByKey(vet, snap, key, value) {
      for(var k in snap) {
        if(snap[k].filterOn != true && snap[k][key] == value) {
          snap[k].filterOn = true;
          vet.push(snap[k]);
        }
      }
    },

    buildMetas() {
      var self = this;
      this.uc = this.ucSel;
      console.log("this.uc",this.uc);
      if(this.ucSel.id != undefined) {
        //console.log("/ucs/"+this.ucSel.id+"/metas");
        //rdb.ref("/curriculo/ucs/"+this.ucSel.id+"/metas").on('value',function(snapshot) {
        self.loading = true;
        rdb.ref("/curriculo/questoes/").orderByChild("ucID").equalTo(this.ucSel.id).on('value',function(snapshot) {
          self.questoes = snapshotValToArray(snapshot.val());
          var metasComQuestoes = {};
          for(var key in self.questoes) {
            metasComQuestoes[self.questoes[key].metaID] = true;
          }
          console.log("questoes");
          console.log(self.questoes);
          rdb.ref("/curriculo/metasNovas/").orderByChild("ucID").equalTo(self.ucSel.id).on('value',function(snapshot) {
            self.metas = [];
            var metasProv = {};
            var metas = snapshot.val();
            for(var key in metas) {
              if(metas[key].nova == true) {
                metasProv[key] = metas[key];
              }
            }
            for(var key in metas) {
              if(metasComQuestoes[key] != undefined) {
                metas[key].a2 = true;
                metasProv[key] = metas[key];
              } else {
                metasProv[key] = metas[key];
              }
            }
            //self.pushByKey(self.metas,metas,"nova",true);
            //self.pushByKey(self.metas,metas,"a2",true);
            //self.pushByKey(self.metas,metas,"a2",undefined);
            self.metas = objToArray(metasProv);
            console.log("self.metas",self.metas);
            self.loading = false;
          });
        });
        rdb.ref("/curriculo/metasNovasDescontinuadas/").orderByChild("ucID").equalTo(this.ucSel.id).on('value',function(snapshot) {
          self.metasDescontinuadas = snapshotValToArray(snapshot.val());
          console.log("metasDescontinuadas");
          console.log(self.metasDescontinuadas);
        });
      }
    },

    adicionarMeta() {
      console.log("adicionarMeta");
      this.$refs.txtareadialog.show();
    },

    novoTexto(meta) {
      console.log("novaMeta",meta);
      console.log("this.uc",this.uc);
      var updates = {};
      updates["/curriculo/metasNovas/"+meta.id] = meta;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("metas atualizadas");
      this.toastS("Meta adicionada com sucesso!");
      this.buildMetas();
      this.refresh =+ 20;
    },

    moveDown(item) {
      var self = this;
      console.log("moveDown",item);
      return;
      var vetOrdem = self.ordem.split(";");
      var pos1 = vetOrdem.lastIndexOf(item.id);
      var aux = vetOrdem[pos1];
      vetOrdem[pos1] = vetOrdem[pos1+1];
      vetOrdem[pos1+1] = aux;
      var newOrdem = vetOrdem.join(";");
      console.log("newOrdem",newOrdem);
      rdb.ref("/curriculo/conteudos/ucs/"+this.uc.id+"/ordem").set(newOrdem);
    },

    moveUp(item) {
      var self = this;
      console.log("moveUp",item);
      return;
      var vetOrdem = self.ordem.split(";");
      var pos1 = vetOrdem.lastIndexOf(item.id);
      var aux = vetOrdem[pos1];
      vetOrdem[pos1] = vetOrdem[pos1-1];
      vetOrdem[pos1-1] = aux;
      var newOrdem = vetOrdem.join(";");
      console.log("newOrdem",newOrdem);
      rdb.ref("/curriculo/conteudos/ucs/"+this.uc.id+"/ordem").set(newOrdem);
    }


  }
}
</script>

<style scoped>

</style>
