<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dense :color="hexTints(area.cor,0.95)">
        <v-btn class="ms-0 me-2" icon dark small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ms-1 ps-0 fs-12pt f-lato fw-600">Editar Meta de compreensão</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3">
        <p class="p-0 m-0 mb-1">Nível de compreensão da Meta</p>
        <v-select class="p-0 m-0" solo v-model="meta.nivel" hide-details :items="['lembrar','entender','aplicar','analisar','avaliar','criar']">
          <template v-slot:item="{item}">
            <niveisicon :nivel="item" size="w30" :color="area.cor"/>
            <span v-cHex="area.cor" class="ms-2 fs-11pt fw-500 f-raleway pt-2">{{item}}</span>
          </template>
          <template v-slot:selection="{item}">
            <niveisicon :nivel="item" size="w36" :color="area.cor"/>
            <span v-cHex="area.cor" class="ms-2 fs-12pt fw-500 f-raleway pt-1">{{item}}</span>
          </template>
        </v-select>
        <v-textarea class="mb-0 f-lato fs-11pt" v-model="meta.texto" @update:error="erroUpdate" label="Digite texto da meta de compreensão" :rules="rules" auto-grow solo rows="8"></v-textarea>
        <v-divider class="mt-0"></v-divider>
        <div class="text-center">
          <v-btn color="" small @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn class="ms-4" color="success" small :disabled="!erroValor" @click="adicionar">
            Alterar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { rdb, ge } from '@/firebasedb.js'

export default {
  name: "textAreaDialogEdit",
  components: {
    'niveisicon': () => import('./niveiscompreensao/niveis-icons.vue'),
  },
  props: {
    mensagem: { default: "", type: String },
    color: { default: "#000000", type: String },
    fieldTitle: { default: "", type: String },
    acao: { default: "Adicionar", type: String },
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: { },
  data(){
    return {
      nivelSel: "lembrar",
      texto: "",
      msgProp: "",
      dialog: false,
      rules: [
        value => !!value || 'Texto obrigatório!',
        value => (value && value.length >= 10) || 'Mínimo 10 caracteres',
      ],
      erroValor: false,
      meta: {
        ativada: true,
        nivel: "lembrar",
        banco: "",
        bancoCodigo: "",
        createdAt: "",
        emProducao: true,
        id: "xxx",
        metaCodigo: "",
        nova: true,
        texto: "",
        ucID: "xxx",
        ucNome: ""
      },
    }
  },
  watch: {
    mensagem(to, from) {
      var self = this;
      this.build();
    },
    dialog(to, from) {
      var self = this;
    },
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
    },

    show(meta) {
      console.log("meta",meta);
      if(meta == undefined) {
        var newKey = rdb.ref("/curriculo/metasNovas").push().key;
        this.meta = {
          ativada: true,
          nivel: "lembrar",
          banco: "",
          bancoCodigo: "",
          createdAt: moment().format(),
          emProducao: true,
          id: newKey,
          metaCodigo: "",
          nova: true,
          texto: "",
          ucID: this.uc.id,
          ucNome: this.uc.nome,
        };
        console.log("metaNova",this.meta);
      } else {
        this.meta = meta;
        if(this.meta.nivel == undefined) {
          this.meta.nivel = "lembrar";
        }
      }
      this.erroValor = true;
      this.refresh++;
      this.dialog = true;
      this.$forceUpdate();
    },

    erroUpdate(value) {
      this.erroValor = !value;
      console.log(value);
    },

    adicionar() {
      this.dialog = false;
      this.$emit('changeValue',this.meta);
    }

  }
}

</script>

<style scoped>

</style>
