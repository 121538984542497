<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="m-0 p-0">
        <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
          <v-container fluid class="m-0 p-2 px-3">
            <v-row dense align="center" justify="start" class="p-0">
              <v-col cols="auto" class="m-0 p-0">
                <v-avatar dark :color="hexTints(area.cor,0.8)" size="22" class="m-0 p-0">
                  <i v-i:duo#target#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                </v-avatar>
              </v-col>
              <v-col cols="auto" class="m-0 p-0 ms-2">
                <div class="mt-0 line-height-1 f-lato fw-600 fs-8pt text-truncate">
                  Meta de compreensão
                </div>
              </v-col>
              <v-col cols="auto" class="m-0 p-0" v-if="meta.metaCodigo != ''">
                  <span class="ms-2 f-sanspro fs-8pt fw-600">ID:</span>
                  <span class="ms-2 f-roboto fs-8pt fw-700">{{meta.metaCodigo}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card outlined class="mb-0 pb-4" v-if="meta != null">
          <v-card-text class="text-left text-justify pb-4">
            <div class="row pb-0 pt-0">
              <div class="col-12 col-lg-12 px-0 py-0 my-0 fs-10pt">
                <v-card outlined height="22px" v-bg:b#9 v-if="(isGestores || isUserAdmin)">
                  <div class="right p-0 m-0 line-height-1">
                    <v-btn v-c:D class="" text x-small @click="$refs.promptdialogref.show(meta)" v-if="false && meta.ativada == undefined">
                      <i v-i:duo#x-circle#14 class="" style="margin-top: -3px; margin-right: 1px;"></i>
                      <span class="text-nonecase f-roboto fw-300 fs-8pt">remover meta</span>
                      <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
                    </v-btn>
                    <v-btn v-c:D class="" text x-small @click="$refs.txtareadialogeditref.show(meta)" >
                      <i v-i:duo#pencil#16 class="" style="margin-top: -3px; margin-right: 1px;"></i>
                      <span class="text-nonecase f-roboto fw-300 fs-8pt">editar</span>
                      <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
                    </v-btn>
                    <v-btn v-c:D class="" text x-small @click="$refs.promptdialogdescontinuarref.show(meta)" >
                      <i v-i:ic#settings-backup-restore#18 class="" style="margin-top: -3px; margin-right: 1px;"></i>
                      <span class="text-nonecase f-roboto fw-300 fs-8pt">descontinuar</span>
                      <spanblocked :area="area" equipeID="gestores" :color="area.cor" icon />
                    </v-btn>
                  </div>
                </v-card>
                <div class="clear m-0 mb-1 p-0"></div>
                <div class="m-0 p-0" v-if="isGestores || isUserAdmin">
                  <div class="right mx-0 px-0 mb-5">
                    <qtdequestoespormeta :area="areaSel" :uc="ucSel" :meta="meta" />
                  </div>
                  <div class="left mx-2 px-0" v-if="meta.metaCodigo != ''">
                    <span class="rounded px-1 me-2" v-bg:S#5>
                      <span class="ms-0 f-sanspro fs-8pt fw-600">ID:</span>
                      <span class="ms-2 f-roboto fs-8pt fw-700">{{meta.metaCodigo}}</span>
                    </span>
                  </div>
                  <span class="ms-1 border rounded p-1" v-if="meta.nivel != undefined">
                    <niveisicon :nivel="meta.nivel" size="w26" :color="hexShades(area.cor,0.2)"/>
                    <span class="ms-1 fs-10pt f-raleway fw-500">{{meta.nivel}}</span>
                  </span>
                </div>
                <div class="clear mx-2">
                  <readmore class="text-dark mt-0 fs-10pt f-raleway fw-400" size="200" :text="meta.texto" />
                  <v-row>
                    <v-col cols="12" class="m-0 p-0 mt-3">
                      <div class="m-0 me-3 mt-0 p-0" v-if="meta.createdAt != undefined" :style="'color:'+hexShades(area.cor,.6)">
                        <div class="right f-raleway fs-8pt fw-300">atualizado {{$moment(meta.createdAt).fromNow()}}</div>
                        <div v-i:duo#clock#12 class="right me-1 mt-n1 pt-0" :style="'color:'+hexShades(area.cor,.6)"></div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="m-0 p-0 mt-1">
                      <feedcontroler ref="fcontroler" :area="area" :uc="ucSel" :color="area.cor" :rdbPath="metaPath+'/'+meta.id" :key="refresh"/>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <promptdialogdescontinuar ref="promptdialogdescontinuarref" @confirm="confirmaDescontinuar" />
    <textareadialogedit ref="txtareadialogeditref" :uc="ucSel" :area="areaSel" fieldTitle="Meta de compreensão" mensagem="teste" @changeValue="editar" />
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponent/feedControler.vue'
import descontinuarbtn from '@/views/cursos/planos/descontinuarBtn.vue'
import moment from 'moment';
import promptdialog from "./promptdialog.vue"
import promptdialogdescontinuar from "./promptdialogDescontinuar.vue"
import qtdequestoespormeta from "./qtdeQuestoesPorMeta.vue"
import spanblocked from "./spanBlocked.vue"
import textareadialogedit from '@/views/cursos/planosv2/metas/textAreaDialogEdit.vue'

export default {
  name: "metaItem",
  components: {
    readmore, feedcontroler, spanblocked, descontinuarbtn, promptdialog,
    promptdialogdescontinuar, qtdequestoespormeta, textareadialogedit,
    'niveisicon': () => import('./niveiscompreensao/niveis-icons.vue'),
 },
  props: [ "areaSel", "ucSel", "metaSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    },
    scolor: function() { return 'color:'+this.area.cor+';'; },
    scolor20: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor30: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor50: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
    scolor70: function() { return 'color:'+this.hexTints(this.area.cor,50)+';'; },
  },
  data(){
    return {
      metaPath: null,
      descontinuados: [],
      meta: null,
      uc: null,
      area: null,
      questoes: [],
      refresh: 0,
    }
  },
  watch: {
    metaSel(to, from) {
      var self = this;
      //console.log("metaItem change meta");
      this.buildMeta();
    },
    ucSel(to, from) {
      var self = this;
      //console.log("metaItem change uc");
      this.buildMeta();
    },
    areaSel(to, from) {
      var self = this;
      //console.log("metaItem change area");
      this.buildMeta();
    }
  },
  mounted: function() {
    var self = this;
    this.buildMeta();
  },
  methods:{

    buildMeta() {
      var self = this;
      this.uc = this.ucSel;
      this.area = this.areaSel;
      var metaPath = "/curriculo/socialData/metasNovas/";
      //var metaPath = "/curriculo/metas/";
      //console.log(metaPath);
      this.metaPath = metaPath;

      if(this.metaSel.id != undefined) {
        //console.log("/ucs/"+this.ucSel.id+"/metas/"+this.metaSel.id);
        rdb.ref("/curriculo/metasNovas/"+this.metaSel.id).on('value',function(snapshot) {
          self.meta = snapshot.val();
        });

        rdb.ref("/curriculo/questoes/").orderByChild("metaID").equalTo(this.metaSel.id).on('value',function(snapshot) {
          self.questoes = snapshotValToArray(snapshot.val());
          //console.log("questoes");
          //console.log(self.questoes);
        });
      }

    },

    descontinuar(novoTexto) {
      //console.log("novoTexto");
      //console.log(novoTexto);
      //console.log(this.meta);
      //console.log(this.uc);
      var updates = {};
      updates["/curriculo/metasNovasDescontinuadas/"+this.meta.id] = this.meta;
      updates["/curriculo/metasNovas/"+this.meta.id] = null;
      rdb.ref().update(updates);
    },

    confirmaDescontinuar() {
      //console.log("confirmaDescontinuar");

      var updates = {};

      var metaObj = clone2(this.meta);
      metaObj.ativada = false;
      //metaObj.texto = metaObj.meta;

      updates["/curriculo/metasNovasDescontinuadas/"+metaObj.id] = metaObj;
      updates["/curriculo/metasNovas/"+metaObj.id] = null;
      //console.log("updates",updates);
      rdb.ref().update(updates);
      this.toastS("Meta DESCONTINUADA com sucesso!");

    },

    editar(meta) {
      console.log("editar",meta);
      var updates = {};
      updates["/curriculo/metasNovas/"+meta.id] = meta;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("metas atualizadas");
      this.toastS("Meta alterada com sucesso!");
    }


  }
}
</script>

<style scoped>

</style>
